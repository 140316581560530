import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { UPDATE_USER, CREATE_USER } from "../../../graphql/mutations";
import { GET_USER } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import { isUUID } from "../../../util/validators";
import { ValidationInput } from "../../../components/Input";
import Button, { COLOR } from "../../../components/Button";
import { routes } from "../../../routes";
import Select from "../../../components/Select";

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const permissionsArray = [
  { id: "ADMIN", name: "Administrator" },
  { id: "GUARDIAN", name: "Opiekun" },
];

const Form = styled.form``;
const InputBox = styled.div`
  width: calc(50% - 10px);
`;
const InputsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function User({ match }) {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);

  if (isUUID(match.params.id) && !isEdit) {
    setIsEdit(true);
  }

  const { data, loading: fetchLoading } = useQuery(GET_USER, {
    variables: { id: match.params.id },
  });

  useEffect(() => {
    if (!fetchLoading && isEdit) {
      const user = data.getUser;
      const permission = permissionsArray.filter(
        (permission) => permission.id === user.permission
      )[0];

      setValue("username", user.username);
      setValue("email", user.email);
      setValue("permission", permission);
    } else if (!fetchLoading) {
      setValue("permission", permissionsArray[1]);
    }
  }, [fetchLoading, data, isEdit, setValue]);

  const [updateUserMutation, { loading: updateLoading }] = useMutation(
    UPDATE_USER,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
      },
    }
  );

  const [createUserMutation, { loading: createLoading }] = useMutation(
    CREATE_USER,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
        history.push(routes.admin.users);
      },
    }
  );

  const onSubmit = (data) => {
    if (isEdit) {
      updateUserMutation({
        variables: {
          ...data,
          id: match.params.id,
          permission: data.permission.id,
        },
      });
    } else {
      createUserMutation({
        variables: {
          ...data,
          permission: data.permission.id,
        },
      });
    }
  };

  return (
    <MainTemplate
      name={isEdit ? "Edycja użytkownika" : "Tworzenie użytkownika"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <InputBox>
            <ValidationInput
              {...register("username", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Nazwa użytkownika"
              label="Nazwa użytkownika"
              error={errors?.username?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              {...register("email", {
                required: { value: true, message: "Pole nie może być puste" },
                pattern: {
                  value: emailRe,
                  message: "Podaj poprawny adres email",
                },
              })}
              placeholder="Email"
              label="Email użytkownika"
              error={errors?.email?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  items={permissionsArray}
                  isLoading={fetchLoading}
                  label="Uprawnienia"
                  {...rest}
                />
              )}
              control={control}
              name="permission"
            />
          </InputBox>
        </InputsBox>
        <ButtonBox>
          <Button
            as={Link}
            to={routes.admin.users}
            color={COLOR.GREY}
            margin="0 0 0 25px"
          >
            Anuluj
          </Button>
          <Button
            value={isEdit ? "Zapisz" : "Dodaj użytkownika"}
            input
            isLoading={createLoading || updateLoading}
            margin="0 0 0 25px"
          />
        </ButtonBox>
      </Form>
    </MainTemplate>
  );
}
