import React, { useRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Loader from "../components/Loader";
import { theme } from "../theme/theme";

const { primaryBlue, white, red, grey } = theme.color;
export const COLOR = {
  DEFAULT: {
    color: primaryBlue,
    text: white,
    border: "transparent",
  },
  RED: {
    color: "transparent",
    text: red,
    border: red,
  },
  GREY: {
    color: "transparent",
    text: grey,
    border: grey,
  },
  GREEN: {
    color: "green",
    text: "white",
    border: "transparent",
  },
};

const ButtonContainer = styled.div`
  position: relative;
  margin: ${({ margin }) => margin && margin};
  user-select: none;
`;

const RegularButton = styled.div`
  display: flex;
  justify-content: center;
  min-height: 40px;
  width: fit-content;
  min-width: 136px;
  padding: 8px 22px 9px;
  background-color: ${({ color }) => color.color};
  color: ${({ color }) => color.text};
  font-weight: ${({ theme }) => theme.weight.bold};
  border: 1px solid ${({ color }) => color.border};
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  ${({ loader }) =>
    loader &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;
const InputButton = styled(RegularButton)`
  border: none;
`;

const LoaderElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ buttonDim }) => `${buttonDim.width}px`};
  height: ${({ buttonDim }) => `${buttonDim.height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Button({
  children,
  input,
  color = COLOR.DEFAULT,
  isLoading: loader,
  margin,
  ...rest
}) {
  const [buttonDim, setButtonDim] = useState({ width: 0, height: 0 });
  const button = useRef(null);

  useEffect(() => {
    const { width, height } = button.current.getBoundingClientRect();
    setButtonDim({ width, height });
  }, [setButtonDim]);

  if (input) {
    return (
      <ButtonContainer margin={margin}>
        <InputButton
          as="input"
          type="submit"
          loader={loader}
          color={color}
          ref={button}
          {...rest}
        />
        {loader && (
          <LoaderElement buttonDim={buttonDim}>
            <Loader loader={loader} size={30} />
          </LoaderElement>
        )}
      </ButtonContainer>
    );
  } else {
    return (
      <ButtonContainer margin={margin}>
        <RegularButton ref={button} loader={loader} color={color} {...rest}>
          {children}
        </RegularButton>
        {loader && (
          <LoaderElement buttonDim={buttonDim}>
            <Loader loader={loader} size={30} />
          </LoaderElement>
        )}
      </ButtonContainer>
    );
  }
}
