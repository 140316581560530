import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
} from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const httpLink = createUploadLink({
  uri: SERVER_URL,
});

const authLink = setContext(() => {
  const token =
    localStorage.getItem("token") || localStorage.getItem("reseller_token");
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  fetchOptions: {
    mode: "no-cors",
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: offsetLimitPagination(),
        },
      },
    },
  }),
  defaultOptions: defaultOptions,
});

export default function ApolloProvider(props) {
  return <Provider client={client} {...props} />;
}
