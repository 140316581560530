import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { UPDATE_RESELLER, CREATE_RESELLER } from "../../../graphql/mutations";
import { GET_RESELLER, LIST_USERS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import { isUUID } from "../../../util/validators";
import { ValidationInput } from "../../../components/Input";
import Button, { COLOR } from "../../../components/Button";
import { routes } from "../../../routes";
import Select from "../../../components/Select";
import { useCheckPermission } from "../../../hooks/useCheckPermission";
import { useAuthState } from "../../../context/auth";

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Form = styled.form``;
const InputBox = styled.div`
  width: calc(50% - 10px);
`;
const InputsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function Reseller({ match }) {
  const history = useHistory();
  const { user } = useAuthState();
  const { isAdmin } = useCheckPermission();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);

  if (isUUID(match.params.id) && !isEdit) {
    setIsEdit(true);
  }

  const { data, loading: fetchLoading } = useQuery(GET_RESELLER, {
    variables: { id: match.params.id },
  });

  const [users, setUsers] = useState([]);
  const [listUsers, { loading: userListLoading }] = useLazyQuery(LIST_USERS, {
    onCompleted: ({ listUsers }) => {
      const users = listUsers.map((user) => ({
        id: user.id,
        name: user.username,
      }));
      setUsers(users);
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (!fetchLoading && isEdit) {
      const reseller = data.getReseller;

      setValue("name", reseller.name);
      setValue("email", reseller.email);
      setValue("nip", reseller.nip);
      setValue("user", { id: reseller.user.id, name: reseller.user.username });
    }
  }, [fetchLoading, data, isEdit, setValue]);

  const [updateResellerMutation, { loading: updateLoading }] = useMutation(
    UPDATE_RESELLER,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err.graphQLErrors);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
      },
    }
  );

  const [createResellerMutation, { loading: createLoading }] = useMutation(
    CREATE_RESELLER,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
        history.push(routes.admin.resellers);
      },
    }
  );

  const onSubmit = (data) => {
    console.log(data);
    if (isEdit) {
      updateResellerMutation({
        variables: {
          ...data,
          id: match.params.id,
          userId: data.user.id,
        },
      });
    } else {
      createResellerMutation({
        variables: { ...data, userId: isAdmin ? data.user.id : user.id },
      });
    }
  };

  return (
    <MainTemplate name={isEdit ? "Edycja resellera" : "Tworzenie resellera"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <InputBox>
            <ValidationInput
              {...register("name", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Wprowadź nazwę"
              label="Nazwa resellera"
              error={errors?.name?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              {...register("nip", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Wprowadź nip"
              label="Nip resellera"
              error={errors?.nip?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox>
            <ValidationInput
              {...register("email", {
                pattern: {
                  value: emailRe,
                  message: "Podaj poprawny adres email",
                },
              })}
              placeholder="Wprowadź email"
              label="Email resellera"
              error={errors?.email?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
          {isAdmin && (
            <InputBox>
              <Controller
                render={({ ref, ...rest }) => (
                  <Select
                    isLoading={fetchLoading}
                    items={users}
                    getItems={listUsers}
                    isListLoading={userListLoading}
                    label="Opiekun"
                    placeholder="Wybierz opiekuna"
                    error={errors?.user?.message}
                    {...rest}
                  />
                )}
                rules={{
                  required: { value: true, message: "Pole nie może byc puste" },
                }}
                control={control}
                name="user"
              />
            </InputBox>
          )}
        </InputsBox>
        <ButtonBox>
          <Button
            as={Link}
            to={routes.admin.resellers}
            color={COLOR.GREY}
            margin="0 0 0 25px"
          >
            Anuluj
          </Button>
          <Button
            value={isEdit ? "Zapisz" : "Dodaj użytkownika"}
            input
            isLoading={createLoading || updateLoading}
            margin="0 0 0 25px"
          />
        </ButtonBox>
      </Form>
    </MainTemplate>
  );
}
