import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { FcCheckmark } from "react-icons/fc";
import { FcUndo } from "react-icons/fc";
import { format } from "date-fns";

import { routes } from "../../../routes";

import { setProceedingsList } from "../../../redux/actions";
import { LIST_PROCEEDINGS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import DataTable from "../../../components/DataTable";
import StatusItem from "../../../components/StatusItem";
import { useProceedingState } from "../../../context/proceedingFiltration";
import { usePagination } from "../../../hooks/usePagination";
import Pagination from "../../../components/Pagination";
import { ProceedingStatus } from "../../../util/variables";
import useUpdateProceedingStatus from "../../../hooks/useUpdateProceedingStatus";
import { useCheckPermission } from "../../../hooks/useCheckPermission";
import { useAuthState } from "../../../context/auth";
import { truncateText } from "../../../util/functions";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const Accept = styled.div`
  display: flex;
  cursor: pointer;
`;

export default function ProceedingsWaiting() {
  const columns = useMemo(
    () => [
      {
        Header: "Klient",
        accessor: "clientName",
      },
      {
        Header: "NIP klienta",
        accessor: "clientNip",
      },
      {
        Header: "Email klienta",
        accessor: "clientEmail",
      },
      {
        Header: "Data zgłoszenia",
        accessor: "createdAt",
      },
      {
        Header: "Reseller",
        accessor: "resellerName",
      },
      {
        Header: "NIP Resellera",
        accessor: "resellerNip",
      },
      {
        Header: "Dotyczy",
        accessor: "section",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        accessor: "accept",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ],
    []
  );

  const { isGuardian } = useCheckPermission();
  const { user } = useAuthState();

  const { paginationState, paginationDispatch } = usePagination({ limit: 25 });

  const dataDispatch = useDispatch();
  const filtrationState = useProceedingState();

  useEffect(() => {
    paginationDispatch({ type: "RESET" });
  }, [filtrationState, paginationDispatch]);

  const { loading } = useQuery(LIST_PROCEEDINGS, {
    variables: {
      filter: {
        status: ProceedingStatus.WAITING,
        userId: isGuardian ? user.id : null,
      },
      offset: paginationState.offset,
      limit: paginationState.limit,
    },
    onError: (err) => {
      console.log(err);
    },
    onCompleted(data) {
      const { items, count } = data.listProceedings;
      dataDispatch(setProceedingsList(items));
      paginationDispatch({ type: "COUNT", payload: count });
    },
  });

  const proceedings = useSelector((state) => state.proceedings);

  const updateStatus = useUpdateProceedingStatus();
  const handleStatusChange = (variables) => {
    updateStatus(variables);
  };

  const data = proceedings?.reduce((acc, proceeding) => {
    const status = <StatusItem value={proceeding.status} />;

    acc = [
      ...acc,
      {
        resellerName: proceeding.reseller.name,
        resellerNip: proceeding.reseller.nip,
        status,
        clientName: (
          <Link
            to={`${routes.admin.proceeding}/${proceeding.id}/show`}
            title={proceeding.clientName}
          >
            {truncateText(proceeding.clientName)}
          </Link>
        ),
        clientNip: proceeding.clientNip,
        clientEmail: proceeding.clientEmail,
        section: proceeding.section.name,
        createdAt: format(
          new Date(parseInt(proceeding.createdAt)),
          "dd-MM-yyyy"
        ),
        accept: (
          <Accept>
            {proceeding.status === ProceedingStatus.WAITING ? (
              <FcCheckmark
                title="Akceptuj projekt"
                size={22}
                onClick={() =>
                  handleStatusChange({
                    id: proceeding.id,
                    status: ProceedingStatus.OPEN,
                  })
                }
              />
            ) : (
              <FcUndo
                size={22}
                onClick={() =>
                  handleStatusChange({
                    id: proceeding.id,
                    status: ProceedingStatus.WAITING,
                  })
                }
              />
            )}
          </Accept>
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Projekty oczekujące">
      <DataTable data={data} columns={columns} loading={loading} />
      <Pagination
        paginationState={paginationState}
        paginationDispatch={paginationDispatch}
      />
    </MainTemplate>
  );
}
