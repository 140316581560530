import React, { useMemo } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";

import { setResellersList } from "../../../redux/actions";
import { LIST_RESELLERS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import DataTable from "../../../components/DataTable";
import AddButton from "../../../components/AddButton";
import { routes } from "../../../routes";
import { useCheckPermission } from "../../../hooks/useCheckPermission";

const OptionsPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
`;
const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const LightText = styled.div`
  color: ${({ theme }) => theme.color.grey};
`;

export default function Resellers() {
  const dataDispatch = useDispatch();
  const { isGuardian } = useCheckPermission();

  const { loading } = useQuery(LIST_RESELLERS, {
    onError: (err) => {
      console.log(err);
    },
    onCompleted(data) {
      dataDispatch(setResellersList(data.listResellers));
    },
  });

  const resellers = useSelector((state) => state.resellers);

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "Nazwa",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "NIP",
        accessor: "nip",
      },
      {
        Header: "Opiekun",
        accessor: "user",
      },
      {
        accessor: "edit",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ];
    if (isGuardian) {
      cols = cols.filter((obj) => obj.accessor !== "edit");
    }

    return cols;
  }, [isGuardian]);

  const data = resellers?.reduce((acc, reseller) => {
    acc = [
      ...acc,
      {
        name: reseller.name,
        email: reseller.email || <LightText>Nie podano maila</LightText>,
        nip: reseller.nip,
        user: reseller.user.username,
        edit: (
          <Link to={`${routes.admin.reseller}/${reseller.id}`}>
            <MdModeEdit size={22} />
          </Link>
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Resellerzy">
      <OptionsPanel>
        <AddButton as={Link} to={`${routes.admin.reseller}/create`} />
      </OptionsPanel>
      <DataTable data={data} columns={columns} loading={loading} />
    </MainTemplate>
  );
}
