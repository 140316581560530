import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { defaultStyles, FileIcon } from "react-file-icon";
import _ from "lodash";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";

const BackgroundIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: ${({ theme }) => theme.color.black};
  transition: color 0.14s ease-in-out;
  z-index: -1;
`;
const DropzoneBox = styled.div`
  position: relative;
  width: 100%;

  :hover {
    cursor: pointer;

    ${BackgroundIcon} {
      color: ${({ theme }) => theme.color.lightBlue};
    }
  }
`;
const Dropzone = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  min-height: 100px;
  border: 1px dashed ${({ theme }) => theme.color.black};
  border-radius: 4px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const FilePreviewBox = styled.div`
  position: relative;
`;
const RemoveIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 16px;
  color: red;
`;

const UploadFiles = ({ field: { onChange } }) => {
  const [filePreviewArray, setFilePreviewArray] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...filePreviewArray, ...acceptedFiles];
      const noDuplicateFiles = _.uniqBy(files, ({ size, path }) =>
        [size, path].join()
      );
      if (!_.isEqual(files, noDuplicateFiles))
        toast.warning("Jeden z plików jest już dodany");

      setFilePreviewArray(noDuplicateFiles);
      onChange(noDuplicateFiles);
    },
    // eslint-disable-next-line
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <DropzoneBox {...getRootProps()}>
      <BackgroundIcon>
        <AiOutlineCloudUpload />
      </BackgroundIcon>
      <input {...getInputProps()} />
      <Dropzone>
        {filePreviewArray.map((file) => {
          const [type, extension] = file.type.split("/");

          let previewMarkdown;
          if (type === "image") {
            const url = URL.createObjectURL(file);
            previewMarkdown = <Image src={url} alt="Preview" />;
          } else {
            previewMarkdown = (
              <FileIcon extension={extension} {...defaultStyles[extension]} />
            );
          }
          return (
            <FilePreviewBox
              key={file.path}
              onClick={(e) => {
                e.stopPropagation();
                const newFilesArray = filePreviewArray.filter(
                  ({ lastModified }) => lastModified !== file.lastModified
                );
                onChange(newFilesArray);
                setFilePreviewArray(newFilesArray);
              }}
              title={file.name}
            >
              <RemoveIcon>
                <IoIosCloseCircle />
              </RemoveIcon>
              {previewMarkdown}
            </FilePreviewBox>
          );
        })}
      </Dropzone>
    </DropzoneBox>
  );
};

export default UploadFiles;
