import React, { forwardRef } from "react";
import styled from "styled-components";

import { Label } from "./admin/Label";
import Loader from "./Loader";

export const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
  padding: 11px 18px 10px;
  color: ${({ theme, error }) =>
    error ? theme.color.lightRed : theme.color.grey};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  resize: none;

  ::placeholder {
    color: ${({ theme, error }) =>
      error ? theme.color.lightRed : theme.color.grey};
  }

  :disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.color.lightGrey};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset, 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
    -webkit-text-fill-color: ${({ theme, error }) =>
      error ? theme.color.lightRed : theme.color.grey};
  }
`;
const Error = styled.div`
  position: absolute;
  bottom: 0px;
  left: 4px;
  transform: translateY(100%);
  font-size: 12px;
  color: ${({ theme }) => theme.color.red};
  font-weight: ${({ theme }) => theme.weight.regular};
`;
const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
const LoaderBox = styled.div`
  position: absolute;
  display: flex;
  top: 55%;
  right: 18px;
`;

export const ValidationInput = forwardRef(
  ({ error, isLoading, label, ...rest }, ref) => {
    return (
      <InputContainer>
        {label && <Label htmlFor={rest.name}>{label}</Label>}
        <Input
          ref={ref}
          error={error}
          disabled={isLoading}
          autocomplete={false}
          id={rest.name}
          {...rest}
        />
        {isLoading && (
          <LoaderBox>
            <Loader loading={isLoading} size={16} />
          </LoaderBox>
        )}
        {error && (
          <Error>{error.charAt(0).toUpperCase() + error.slice(1)}</Error>
        )}
      </InputContainer>
    );
  }
);
