import { gql } from "@apollo/client";

export const LIST_PROCEEDINGS = gql`
  query listProceedings($filter: ProceedingFilter, $offset: Int, $limit: Int) {
    listProceedings(filter: $filter, offset: $offset, limit: $limit) {
      items {
        id
        clientName
        clientNip
        status
        comment
        extensionCount
        reseller {
          id
          name
          email
          nip
          user {
            id
            username
            email
          }
        }
        section {
          id
          name
          expirationTime
        }
        createdAt
      }
      count
    }
  }
`;

export const GET_PROCEEDING = gql`
  query getProceeding($id: String!) {
    getProceeding(id: $id) {
      id
      status
      clientName
      clientNip
      comment
      extensionCount
      reseller {
        id
        name
        email
        user {
          id
          username
          email
        }
      }
      section {
        id
        name
        expirationTime
      }
      files {
        url
      }
      createdAt
    }
  }
`;

export const IS_PROCEEDING_WAITING = gql`
  query isProceedingWaiting($clientNip: String!) {
    isProceedingWaiting(clientNip: $clientNip)
  }
`;

export const LOGIN_USER = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      username
      permission
      forceChange
      token
    }
  }
`;

export const LIST_USERS = gql`
  query listUsers($filter: ListUsersFilterInput) {
    listUsers(filter: $filter) {
      id
      username
      email
      permission
      createdAt
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      id
      username
      email
      permission
    }
  }
`;

export const LIST_SECTIONS = gql`
  query listSections {
    listSections {
      id
      name
      expirationTime
    }
  }
`;

export const GET_SECTION = gql`
  query getSection($id: String!) {
    getSection(id: $id) {
      id
      name
      expirationTime
    }
  }
`;

// Reseller
export const LIST_RESELLERS = gql`
  query listResellers {
    listResellers {
      id
      name
      email
      nip
      user {
        username
      }
    }
  }
`;
export const LOGIN_RESELLER = gql`
  query loginReseller($nip: String!, $password: String!) {
    loginReseller(nip: $nip, password: $password) {
      id
      nip
      name
      email
      forceChange
      token
    }
  }
`;

export const GET_RESELLER = gql`
  query getReseller($id: String!) {
    getReseller(id: $id) {
      id
      name
      email
      nip
      user {
        id
        username
      }
    }
  }
`;
