import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { EXTENSION_PROCEEDING } from "../graphql/mutations";

export const useProceedingExtension = () => {
  const [extensionMutation, { loading: extensionLoading }] = useMutation(
    EXTENSION_PROCEEDING,
    {
      onCompleted: () => {
        toast.success("Projekt został przedłużony");
      },
      onError: (err) => {
        toast.error("Ups! Coś poszło nie tak");
        console.log(err);
      },
    }
  );

  const extensionProceeding = (proceedingId) =>
    extensionMutation({ variables: { id: proceedingId } });

  return { extensionProceeding, extensionLoading };
};
