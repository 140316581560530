import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ApolloProvider from "./ApolloProvider";
import ReduxProvider from "./redux/ReduxProvider";
import { AuthProvider } from "./context/auth";
import { AuthResellerProvider } from "./context/authReseller";
import ThemeProvider from "./theme";

import { routes } from "./routes";
import { UserPermission } from "./util/variables";
import { ProceedingFiltrationProvider } from "./context/proceedingFiltration";

import { DynamicAdminRoute, DynamicResellerRoute } from "./util/DynamicRoute";

import Home from "./pages/Home";

import AdminLogin from "./pages/admin/Login";
import AdminForceChangePassword from "./pages/admin/ForceChangePassword";
import AdminRecoveryPassword from "./pages/admin/RecoveryPassword";
import AdminProceedings from "./pages/admin/proceedings/Proceedings";
import AdminProceedingsWaiting from "./pages/admin/proceedings/ProceedingsWaiting";
import AdminProceeding from "./pages/admin/proceedings/Proceeding";
import AdminProceedingShow from "./pages/admin/proceedings/ProceedingShow";
import AdminSections from "./pages/admin/sections/Sections";
import AdminSection from "./pages/admin/sections/Section";
import AdminResellers from "./pages/admin/resellers/Resellers";
import AdminReseller from "./pages/admin/resellers/Reseller";
import AdminUsers from "./pages/admin/users/Users";
import AdminUser from "./pages/admin/users/User";

import ResellerLogin from "./pages/reseller/Login";
import ResellerHome from "./pages/reseller/Home";
import ResellerProceeding from "./pages/reseller/Proceeding";
import ResellerSettings from "./pages/reseller/Settings";
import ResellerRecoveryPassword from "./pages/reseller/RecoveryPassword";
import ResellerForceChangePassword from "./pages/reseller/ForceChangePassword";

const App = () => {
  return (
    <ReduxProvider>
      <ApolloProvider>
        <AuthProvider>
          <AuthResellerProvider>
            <ThemeProvider>
              <ProceedingFiltrationProvider>
                <Router basename="/">
                  <Switch>
                    <Route exact path={routes.home} component={Home} />
                    <DynamicAdminRoute
                      path={routes.admin.login}
                      component={AdminLogin}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.forceChangePassword}
                      component={AdminForceChangePassword}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.recoveryPassword}
                      component={AdminRecoveryPassword}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.proceedings}
                      component={AdminProceedings}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.proceedingsWaiting}
                      component={AdminProceedingsWaiting}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      exact
                      path={`${routes.admin.proceeding}/:id/show`}
                      component={AdminProceedingShow}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      exact
                      path={`${routes.admin.proceeding}/create`}
                      component={AdminProceeding}
                      permission={UserPermission.ADMIN}
                    />
                    <DynamicAdminRoute
                      exact
                      path={`${routes.admin.proceeding}/:id/edit`}
                      component={AdminProceeding}
                      permission={UserPermission.ADMIN}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.sections}
                      component={AdminSections}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      path={`${routes.admin.section}/:id`}
                      component={AdminSection}
                      permission={UserPermission.ADMIN}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.resellers}
                      component={AdminResellers}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      path={`${routes.admin.reseller}/create`}
                      component={AdminReseller}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      path={`${routes.admin.reseller}/:id`}
                      component={AdminReseller}
                      permission={UserPermission.ADMIN}
                    />
                    <DynamicAdminRoute
                      path={routes.admin.users}
                      component={AdminUsers}
                      permission={UserPermission.GUARDIAN}
                    />
                    <DynamicAdminRoute
                      path={`${routes.admin.user}/:id`}
                      component={AdminUser}
                      permission={UserPermission.ADMIN}
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.login}
                      component={ResellerLogin}
                      guest
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.forceChangePassword}
                      component={ResellerForceChangePassword}
                      guest
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.home}
                      component={ResellerHome}
                      protect
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.proceeding}
                      component={ResellerProceeding}
                      protect
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.settings}
                      component={ResellerSettings}
                      protect
                    />
                    <DynamicResellerRoute
                      path={routes.reseller.recoveryPassword}
                      component={ResellerRecoveryPassword}
                      guest
                    />
                    <Route>
                      <h1>404 - not found</h1>
                    </Route>
                  </Switch>
                </Router>
                <ToastContainer
                  transition={Flip}
                  position={toast.POSITION.BOTTOM_RIGHT}
                />
              </ProceedingFiltrationProvider>
            </ThemeProvider>
          </AuthResellerProvider>
        </AuthProvider>
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default App;
